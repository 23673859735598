header, nav {
  padding: .5em 1em !important;
  background-color: #203a8d;
  color: #fff;
}

.btn-primary {
  background-color: #203a8d !important;
}

.text-primary {
  color: #203a8d !important;
}

.navbar-nav {
  margin-left: 1em;  
}

.navbar-nav .active{
  border-bottom: solid 4px white;
}

.navbar-nav .nav-item {
  margin-right: .25em;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
}

.controls-container {
  position: fixed;
  z-index: 10000;
  right: 2em;
  bottom: 1em;
  background-color: rgba(255, 255, 255, 0.850);
  border: 1px solid #203a8d;
  border-radius: 10px;
}

.controls-btn {
  background-color: #203a8d !important;
  color: #fff !important;
}

.map-container-bordered {
  border: 1px solid #f0f0f0;
}

a.nav-link {
  color: white;
}

.btn-main {
  background-color: #203a8d !important;
}

.nav-link{
  padding: 1rem .5rem !important;
}

.dropdown-menu.show {
  z-index: 9999;
}

.login-header {
  background-color: #203a8d;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  position: absolute;
  box-shadow: 0 3px 12px rgba(0,0,0,.5);
}

.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.otp-container {
  justify-content: center;
}

input[type=tel] {
  width: 1.5em !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom-color: #203a8d !important;
}

button:disabled {
  cursor: not-allowed;
}

/* Overrides React Table CSS */
.ReactTable {
  background-color: #fff !important;
}

.rt-thead{
  background-color: #203a8d;
  color: #fff;
}
.rt-resizable-header-content{
  padding: .75em;
  font-weight: bolder;
}

.-btn{
  background-color: #203a8d !important;
  color: #fff !important;
}

.ReactTable .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.ReactTable .rt-tbody .rt-td {
  text-align: center;
}

.ReactTable .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 10px;
  width: 10px;
  color: transparent;
  content: '.';
  background-size: 18px 18px;
  background-repeat: no-repeat; 
  opacity: 0.87;
}

.ReactTable  .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 10px;
  width: 10px;
  z-index: 120;
  color: transparent;
  content: '.';
}
.ReactTable .rt-th.-sort-asc .rt-resizer:before {
  margin-top: .5em;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.ReactTable .rt-th.-sort-desc .rt-resizer:before {
  margin-top: .5em;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
/*  */
.table-header {
  font-weight: bolder !important;
}

.border-icon{
  border: 1px solid #000;
}

.card-body {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125) !important;
  border-radius: .25rem !important;
}

#accounts.dropdown-menu {
  right: 0;
  left: unset !important;
}

.nav-subtitle {
  font-size: 0.6em;
  font-weight: 100;
  padding:0;
  line-height:0;
  margin: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #203a8d;
}

/* REACT SELECT OVERRIDE STYLES */
.react-container {
    width: 100%;
}

.react-select__control {
    /* font-size: small !important; */
    height: 33px !important;
    min-height: 33px !important;
}

.react-select__menu {
    margin-top: 2px !important;
}

.react-select__option {
    font-size: small !important;
}

.react-select__menu-list {
    max-height: 200px !important;
    position: relative !important;
    font-size: small !important;
}

.react-select__indicator.react-select__dropdown-indicator {
    padding: 3px !important;
}

.react-select__indicator.react-select__clear-indicator {
    padding: 0px !important;
}

.react-select__indicator-separator {
    display: none !important;
}

.react-select__value-container {
    font-size: 0.9em !important;
    padding-left: 12px !important;
}

